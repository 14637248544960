<template>
  <div class="library-content-header">
    <h1
      class="library-content-header__title"
      data-testid="headerTitle"
    >
      {{ libraryTitle }} <br>
    </h1>

    <div class="library-content-header__wrapper-options">
      <div class="library-content-header__inputs">
        <!-- SEARCH -->
        <ElementInput
          ref="searchInput"
          v-model="searchValue"
          placeholder="Search"
          size="small"
          clearable
          class="library-content-header__search"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          />
        </ElementInput>

        <!-- SORTING -->
        <ElementCascader
          v-if="sorting"
          ref="sortCascader"
          v-model="currentSorting"
          placeholder="Sort"
          class="library-content-header__select library-content-header__select--small"
          :options="librarySortOptions"
          :props="{ expandTrigger: 'hover', emitPath: false }"
          @change="sortItems"
        />

        <!-- UPLOAD Button -->
        <StitchTooltip
          :message="newItemDisabledMessage"
          :is-disabled="!disableCreateButton"
        >
          <ElementButton
            v-if="showCreateButton"
            :disabled="disableCreateButton"
            type="primary"
            plain
            class="library-content-header__button-create"
            @click="toggleLibraryItemCreateForm"
          >
            {{ newItemTitle }}
          </ElementButton>
        </StitchTooltip>

        <!-- Bulk Create Button -->
        <StitchTooltip
          :message="newItemDisabledMessage"
          :is-disabled="!disableCreateButton"
        >
          <ElementButton
            v-if="showCreateButton && libraryType === LIBRARY_TYPE.STYLE"
            :disabled="disableCreateButton"
            type="primary"
            plain
            class="library-content-header__button-create"
            @click="navigateToBulkCreate"
          >
            Styles Bulk Creation
          </ElementButton>
        </StitchTooltip>
      </div>
    </div>

    <FormGenerator
      :show-form-generator="showLibraryItemCreateForm"
      :form-configuration="formConfiguration"
      :options="camelCasedFilters"
      :default-data="preFilledData"
      :additional-data="additionalData"
      :is-creation="true"
      @form-close="onCreateFormClose"
      @form-complete="formComplete"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'

import Utils from '@/services/utils'
import { getFormConfig } from '@/services/formUtils'

import { FiltersPersistency } from '@/mixins/FiltersPersistency.js'

import { LIBRARY_TYPE } from '@/constants/libraryType'
import { FILTER_TYPE } from '@/constants/filterType'
import { SORT_OPTION } from '@/constants/sortOption'
import FeatureFlags from '@/services/featureFlags'
import { USER_ROLE } from '@/constants/roleType'

export default {
  name: 'LibraryContentHeader',

  mixins: [FiltersPersistency],

  props: {
    sorting: VueTypes.oneOf(Object.values(SORT_OPTION)),
    newItemFiles: VueTypes.instanceOf(FileList)
  },

  data () {
    return {
      LIBRARY_TYPE,
      showLibraryItemCreateForm: false,
      searchValue: '',
      currentSorting: null,
      defaultDataForm: {},
      libraryStrings: {
        [LIBRARY_TYPE.BLOCK]: {
          title: 'Blocks',
          newItemButton: 'New Block',
          disabledMessage: 'As a vendor user, you cannot create Blocks.'
        },
        [LIBRARY_TYPE.FABRIC]: {
          title: 'Fabrics',
          newItemButton: 'New Fabric',
          disabledMessage: 'As a vendor user, you cannot create Fabrics.'
        },
        [LIBRARY_TYPE.STITCH]: {
          title: 'Stitches',
          newItemButton: 'New Stitch',
          disabledMessage: 'As a vendor user, you cannot create Stitches.'
        },
        [LIBRARY_TYPE.TRIM]: {
          title: 'Trims',
          newItemButton: 'New Trim',
          disabledMessage: 'As a vendor user, you cannot create Trims.'
        },
        [LIBRARY_TYPE.STYLE]: {
          title: 'Styles',
          newItemButton: 'New Style',
          disabledMessage: 'Select a team before creating a new Style.'
        },
        [LIBRARY_TYPE.JOB]: {
          title: 'My Renders'
        }
      },
      sortOptions: {
        library: [
          {
            label: 'Last Modified',
            value: SORT_OPTION.LAST_MODIFIED
          },
          {
            label: 'First Modified',
            value: SORT_OPTION.FIRST_MODIFIED
          },
          {
            label: 'Sort By Name',
            value: SORT_OPTION.NAME
          }
        ],
        styles: [
          {
            label: 'Newest First',
            value: SORT_OPTION.NEWEST_FIRST
          },
          {
            label: 'Oldest First',
            value: SORT_OPTION.OLDEST_FIRST
          },
          {
            label: 'Last Modified First',
            value: SORT_OPTION.LAST_MODIFIED
          },
          {
            label: 'Sort By Name',
            value: SORT_OPTION.NAME
          }
        ],
        jobs: [
          {
            label: 'Newest First',
            value: SORT_OPTION.NEWEST_FIRST
          },
          {
            label: 'Oldest First',
            value: SORT_OPTION.OLDEST_FIRST
          },
          {
            label: 'Sort By Name',
            value: SORT_OPTION.NAME
          }
        ]
      },
      formConfiguration: null
    }
  },

  computed: {
    ...mapGetters(['getSearch', 'getAvailableFilters', 'getAppliedFilters']),

    ...mapGetters({
      user: 'getCognitoUserData',
      libraryType: 'getActiveLibraryType',
      jobSearch: 'getJobSearch',
      browzwearAPI: 'getBrowzwearAPI'
    }),

    /**
     * @returns {Array}
     */
    availableFilters () {
      return this.getAvailableFilters()
    },

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    },

    /**
     * @returns {boolean}
     */
    canAccessRenderEngineChoice () {
      return FeatureFlags.canTeamAccessRenderEngineChoice()
    },

    /**
     * for matching the filter/keys in the item
     *
     * @returns {object}
     */
    camelCasedFilters () {
      if (this.canAccessRenderEngineChoice) {
        return Utils.convertObjectKeysToCamelCase({
          ...FILTER_TYPE.RENDER_ENGINE_FILTERS,
          ...this.availableFilters
        })
      }

      return Utils.convertObjectKeysToCamelCase({
        ...this.availableFilters
      })
    },

    /**
     * @returns {object}
     */
    search () {
      return this.getSearch()
    },

    /**
     * @returns {Array}
     */
    appliedFilters () {
      return this.getAppliedFilters()
    },

    /**
     * @returns {object}
     */
    preFilledData () {
      const appliedFiltersFirstElements = {}
      Object.entries(this.appliedFilters || {}).forEach(([key, value]) => {
        let newValue = value

        if (Array.isArray(value)) {
          newValue = value.length > 0 ? value[0] : null
        }

        appliedFiltersFirstElements[key] = newValue
      })

      const singleGroupAccess = this.getDefaultUserGroupFilter()

      // for vendors, company is always added by default
      // the role is used to distinguish between users
      // inside the FormGenerator
      if (this.isVendorUser) {
        appliedFiltersFirstElements.vendorCompanyId =
          this.user.vendor_company.id
        appliedFiltersFirstElements.role = USER_ROLE.VENDOR.name
      }

      return Utils.convertObjectKeysToCamelCase({
        ...appliedFiltersFirstElements,
        ...singleGroupAccess,
        ...this.defaultDataForm
      })
    },

    /**
     * @returns {Array}
     */
    userGroupIds () {
      return this.user.teams.map(team => team.id)
    },

    /**
     * @returns {Array}
     */
    currentGroupFilters () {
      const appliedGroupFilter = this.appliedFilters.division_id || []
      const persistentGroupFilter =
        this.getPersistentFilters(this.libraryType).division_id || []

      return Array.from(
        new Set([...appliedGroupFilter, ...persistentGroupFilter])
      )
    },

    /**
     * @returns {object}
     */
    additionalData () {
      // add fields to multiple-division items
      if ([LIBRARY_TYPE.TRIM, LIBRARY_TYPE.FABRIC].includes(this.libraryType)) {
        // If there's an applied division filter, use it as default
        const mergedGroupFiltersAndUserPermissions = Array.from(
          new Set([...this.currentGroupFilters, ...this.userGroupIds])
        )

        return { divisionIds: [mergedGroupFiltersAndUserPermissions[0]] }
      }

      if (this.isStyleLibrary || this.isBlockLibrary) {
        return { isMain: true }
      }

      return null
    },

    /**
     * @returns {Array}
     */
    librarySortOptions () {
      if (this.isJobLibrary) {
        return this.sortOptions.jobs
      } else if (this.isStyleLibrary) {
        return this.sortOptions.styles
      } else {
        return this.sortOptions.library
      }
    },

    /**
     * @returns {string}
     */
    libraryTitle () {
      return this.libraryStrings[this.libraryType].title || 'DesignHUB'
    },

    /**
     * @returns {string}
     */
    newItemTitle () {
      return this.libraryStrings[this.libraryType].newItemButton || 'New Item'
    },

    /**
     * @returns {string}
     */
    newItemDisabledMessage () {
      return this.libraryStrings[this.libraryType].disabledMessage || ''
    },

    /**
     * @returns {boolean}
     */
    isStyleLibrary () {
      return this.libraryType === LIBRARY_TYPE.STYLE
    },

    /**
     * @returns {boolean}
     */
    isBlockLibrary () {
      return this.libraryType === LIBRARY_TYPE.BLOCK
    },

    /**
     * @returns {boolean}
     */
    isJobLibrary () {
      return this.libraryType === LIBRARY_TYPE.JOB
    },

    /**
     * @returns {boolean}
     */
    showCreateButton () {
      return !(this.isJobLibrary || this.browzwearAPI)
    },

    /**
     * @returns {boolean}
     */
    disableCreateButton () {
      return (
        this.$route?.query?.group_id === 'all' ||
        this.$route?.query?.division_id === 'all' ||
        (this.isVendorUser && this.libraryType !== LIBRARY_TYPE.STYLE)
      )
    }
  },

  watch: {
    /**
     * @param {string} to
     * @param {string} from
     */
    $route (to, from) {
      this.getFormConfiguration()

      if (from.name !== to.name) {
        if (this.isJobLibrary) {
          this.searchValue = this.jobSearch
        } else {
          this.searchValue = this.search
        }

        this.$refs.searchInput.focus()
      }
    },

    /**
     */
    searchValue () {
      this.searchLibrary()
    },

    /**
     */
    sorting () {
      if (this.sorting) {
        this.updateSortItems()
      }
    },

    /**
     */
    newItemFiles () {
      if (this.newItemFiles && this.newItemFiles.length > 0) {
        const newItemFile = this.newItemFiles[0]
        const name = Utils.getFileNameWithoutExtension(newItemFile.name)

        this.defaultDataForm = {
          model3D: [
            {
              name,
              description: name,
              assets_url: newItemFile
            }
          ]
        }

        this.toggleLibraryItemCreateForm()
      }
    }
  },

  async mounted () {
    if (this.libraryType !== LIBRARY_TYPE.JOB) {
      await this.fetchAvailableFilters()
    }

    this.getFormConfiguration()

    if (this.sorting) {
      this.updateSortItems()
    }

    this.initializeFilters()

    this.$refs.searchInput.focus()
  },

  methods: {
    ...mapActions([
      'setSearch',
      'resetItems',
      'setJobSearch',
      'resetJobs',
      'fetchAvailableFilters'
    ]),

    /**
     */
    initializeFilters () {
      const persistentFilters = this.getPersistentFilters(this.libraryType)
      const stateSearch = this.isJobLibrary ? this.jobSearch : this.search

      this.searchValue = persistentFilters.search || stateSearch || ''

      if (this.searchValue !== '') {
        this.searchLibrary()
      }
    },

    /**
     *
     */
    getFormConfiguration () {
      const config = getFormConfig(this.libraryType)

      if (this.libraryType === LIBRARY_TYPE.STYLE) {
        config.steps[2].fields[1].visible = this.canAccessRenderEngineChoice
      } else if (this.libraryType === LIBRARY_TYPE.BLOCK) {
        config.steps[1].fields[1].visible = this.canAccessRenderEngineChoice
      }

      this.formConfiguration = config
    },

    /**
     */
    sortItems () {
      this.$emit('sorting-change', this.currentSorting)
    },

    /**
     */
    toggleLibraryItemCreateForm () {
      this.showLibraryItemCreateForm = !this.showLibraryItemCreateForm
    },

    /**
     * @param {boolean} success
     */
    formComplete (success) {
      if (success) {
        this.toggleLibraryItemCreateForm()
      }
    },

    /**
     */
    onCreateFormClose () {
      this.showLibraryItemCreateForm = false
      this.defaultDataForm = {}
    },

    /**
     *
     */
    navigateToBulkCreate () {
      this.$router.push({
        name: 'BulkActions',
        query: { group_id: this.$route.query.group_id }
      })
    },

    /**
     */
    searchLibrary: _debounce(
      /**
       * This function is not an arrow function because this (Vue) is being used.
       */
      function () {
        let search = this.search

        if (this.isJobLibrary) {
          search = this.jobSearch
        }

        // Called directly because not stored in the localstorage
        this.buildUrl({
          filterGroupName: 'search',
          value: this.searchValue
        })

        if (search === this.searchValue) {
          return
        }

        if (this.isJobLibrary) {
          this.setJobSearch(this.searchValue)
          this.resetJobs()
        } else {
          this.setSearch({ searchQuery: this.searchValue })
          this.resetItems()
        }

        this.$emit('search-update')
      },
      250 // debounce time in ms
    ),

    /**
     */
    updateSortItems () {
      this.currentSorting = this.sorting
    },

    /**
     * @returns {object}
     */
    getDefaultUserGroupFilter () {
      if (this.user.teams?.length === 1) {
        return { [FILTER_TYPE.GROUP_ID]: this.user.teams[0].id }
      }

      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
$content-header-search-width: 210px;
$content-header-input-min-width: spacing(12);
$button-small-padding: 10px 15px;
$title-top: 12px;

.library-content-header {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: spacing(2);
  padding: 0;

  @media (max-width: $media-query-l) {
    flex-wrap: wrap;
  }
}

.library-content-header__title {
  position: relative;
  top: $title-top;
  margin-bottom: 0;
  padding-right: spacing(2);
  color: $grey-dark;
  text-transform: uppercase;

  @media (max-width: $media-query-s) {
    display: none;
  }
}

.library-content-header__wrapper-options {
  display: flex;

  @media (min-width: $media-query-m) {
    flex: 1;
    justify-content: flex-end;
  }
}

.library-content-header__inputs {
  display: inline-flex;
  justify-content: flex-end;

  > * {
    margin-right: spacing(1);

    &:last-child {
      margin-right: 0;
    }
  }
}

.library-content-header__select {
  max-width: spacing(21);
  margin-right: spacing(1);

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }

  @media (max-width: $media-query-s) {
    display: none;
  }
}

.library-content-header__select--small {
  /deep/ .el-input {
    @media (min-width: $media-query-l) {
      min-width: $content-header-input-min-width;
    }
  }
}

.library-content-header__search {
  width: auto;
  max-width: $content-header-search-width;
  margin-right: spacing(1);

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }
}

.library-content-header__button-create {
  padding: $button-small-padding;
  text-transform: capitalize;
}
</style>
